var chapters = require("/usr/local/var/www/virtual/reanalog/scripts/chapters.js")
var videoDuration = require("/usr/local/var/www/virtual/reanalog/scripts/video-duration.js")

var nav = document.getElementById('chapter-nav')

var label = nav.querySelector('.chapter-nav__label')

var onUpdate = function() {}

var items = Array.prototype.map.call(
  nav.querySelectorAll('.chapter-nav__item'),
  function(item, i) {
    return {
      el: item,
      progress: item.querySelector('.chapter-nav__progress'),
      text: item.querySelector('.chapter-nav__item-label').innerHTML,
      index: i,
    }
  }
)

items.forEach(function(item) {
  item.el.addEventListener('mouseenter', function(e) {
    label.innerHTML = item.text
    label.classList.add('hover')
  })

  item.el.addEventListener('mouseleave', function(e) {
    label.innerHTML = items[currentIndex].text
    label.classList.remove('hover')
  })
})

items.forEach(function(item) {
  item.el.addEventListener('click', function(e) {
    onUpdate(item.index)
  })
})

var currentIndex

chapters.forEach(function(chapter, i) {
  var lastChapter = chapters[chapters.length - 1]
  var totalDuration = videoDuration - lastChapter.duration
  items[i].el.style.width = chapter.duration / totalDuration * 100 + '%'

  //items[i].el.style.width = chapter.duration / videoDuration * 100 + '%'
})

function update(index, wheelBuffer, videoTime) {
  if (index !== currentIndex) {
    if (currentIndex > -1) {
      items[currentIndex].el.classList.remove('active')
      items[currentIndex].progress.style.width = 0
    }

    currentIndex = index
    items[index].el.classList.add('active')
    label.innerHTML = items[index].text
  }

  var rel = (videoTime - chapters[index].start) / chapters[index].duration

  rel = rel > 0 ? rel : 0

  items[index].progress.style.width = rel * 100 + '%'
}

function init(options) {
  onUpdate = options.onUpdate
}

function hideLabel() {
  label.classList.remove('is-visible')
}

function showLabel() {
  label.classList.add('is-visible')
}

module.exports = {
  init: init,
  update: update,
  hideLabel: hideLabel,
  showLabel: showLabel,
}
