;(function() {
  var video = document.getElementById('video')

  if (!video) {
    return
  }

  var source = video.querySelector('source')

  var src

  if (screen.width < 1024) {
    video.setAttribute('poster', 'img/frame1.jpg')

    if (Math.max(screen.width, screen.height) < 480) {
      src = source.getAttribute('data-src-mobile')
    } else {
      src = source.getAttribute('data-src-standard-def')
    }
  } else {
    src = source.getAttribute('data-src-desktop')
  }

  source.src = src

  video.load()
})()
