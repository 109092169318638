var wrapper = document.getElementById('scroll-progress')
//var path = document.getElementById('scroll-progress-circle')

//var startAngle = 0
//var radius = 56

var canvas = document.getElementById('scroll-progress__circle')
canvas.width = 120
canvas.height = 120
var context = canvas.getContext('2d')

var x = 60
var y = 60
var r = 56
var s = 1.5 * Math.PI

/*
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  }
}

function describeArc(x, y, radius, startAngle, endAngle){

    var start = polarToCartesian(x, y, radius, endAngle)
    var end = polarToCartesian(x, y, radius, startAngle)

    var arcSweep = endAngle - startAngle <= 180 ? "0" : "1"

    var d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, arcSweep, 0, end.x, end.y
    ].join(" ")

    return d
}

function update(percentage) {

  var angle = percentage * 360
  console.log('angle', angle)
  var temp
  if (startAngle > angle) {
    temp = startAngle
    startAngle = angle
    angle = temp
  }
  path.setAttribute('d', describeArc(radius+4, radius+4, radius, startAngle, angle ))

}
*/

function update(percentage) {
  var degrees = percentage * 360
  var radians = degrees * (Math.PI / 180)

  context.clearRect(0, 0, 120, 120)
  context.strokeStyle = 'rgba(0,0,0,0.8)'
  context.lineWidth = 2
  context.beginPath()
  context.arc(x, y, r, s, radians + s, false)
  context.stroke()
}

function show() {
  update(0)
  wrapper.classList.add('is-active')
}

function hide() {
  update(0)
  wrapper.classList.remove('is-active')
}

update(0)

module.exports = {
  update: update,
  hide: hide,
  show: show,
}
