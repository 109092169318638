var debounce = require("/usr/local/var/www/virtual/reanalog/node_modules/lodash.debounce/index.js")

module.exports = function(project) {
  var videoSrc

  if (screen.width > 1280) {
    videoSrc = project.src['1080']
  } else if (screen.width > 960) {
    videoSrc = project.src['540']
  } else {
    videoSrc = project.src['360']
  }

  var backgroundColor = project.backgroundColor
  var textColor = project.textColor
  var video = document.getElementById('project-video')
  var sourceElement = video.firstElementChild
  var dResize = debounce(resize, 300)
  var videoAspect = project.aspect
  var currentBackgroundSize
  var loop = project.loop
  var nextButton = document.querySelector('.project-nav--next')
  var prevButton = document.querySelector('.project-nav--prev')

  var wrapper = document.getElementById('project')
  var info = wrapper.querySelector('.project-info')
  var infoText = project.info

  var vpW
  var vpH
  var FIT_THRESHOLD = 100
  // when the aspect ratio of the video gets very similar to that of the viewport, we make the video cover

  function load(callback) {
    sourceElement.src = videoSrc

    function onCanplay() {
      video.removeEventListener('canplay', onCanplay)
      callback(null, null)
    }

    video.addEventListener('canplay', onCanplay)
    video.load()
  }

  function start() {
    video.classList.add('is-active')
    video.play()
  }

  function destroy() {
    // remove event listeners if any
    // remove timeouts
    video.classList.remove('is-active')
    sourceElement.src = ''
    video.load()

    window.addEventListener('resize', dResize)
  }

  function init() {
    window.addEventListener('resize', dResize)
    resize()
    info.innerHTML = infoText
    info.style.color = textColor
    video.style.backgroundColor = backgroundColor
    if (loop) {
      video.setAttribute('loop', 'loop')
    } else {
      video.removeAttribute('loop')
    }
    nextButton.style.color = textColor
    prevButton.style.color = textColor
  }

  function resize() {
    vpW = document.documentElement.clientWidth
    vpH = window.innerHeight

    updateBackgroundSize(vpW, vpH)
  }

  function updateBackgroundSize(w, h) {
    var newBackgroundSize
    // check how big the difference between the two aspect ratios is:
    if (getSmallestGap() < FIT_THRESHOLD) {
      newBackgroundSize = 'cover'
    } else {
      newBackgroundSize = 'contain'
    }

    if (newBackgroundSize === currentBackgroundSize) {
      return
    }

    video.classList.remove(currentBackgroundSize)
    video.classList.add(newBackgroundSize)

    currentBackgroundSize = newBackgroundSize
  }

  function getSmallestGap() {
    var vpAr = vpH / vpW
    if (vpAr > videoAspect) {
      // viewport is more portrait than media
      //let mediaHeight = videoAspect * vpW
      return (vpH - videoAspect * vpW) * 0.5
    }
    if (vpAr < videoAspect) {
      // viewport is more landscape than media
      //let mediaWidth = vpH / videoAspect
      return (vpW - vpH / videoAspect) * 0.5
    }
    return 0
  }

  return {
    load: load,
    init: init,
    start: start,
    destroy: destroy,
  }
}
