var loadImages = require("/usr/local/var/www/virtual/reanalog/scripts/load-images.js")

module.exports = function (project) {
  var index
  var slides
  var infoText = project.info
  var timer
  var INTERVAL = 5000
  var slideWrapper = document.getElementById('project-slideshow-wrapper')
  var projectWrapper = document.getElementById('project')
  var info = projectWrapper.querySelector('.project-info')
  var nextButton = document.querySelector('.project-nav--next')
  var prevButton = document.querySelector('.project-nav--prev')
  var activeSlide
  var backgroundColor = project.backgroundColor
  var textColor = project.textColor

  function load (callback) {

    // load all images
    var sources = project.slides.map(function (slide) {
      return slide.image
    })

    // load all images
    loadImages(sources, function (images) {
      callback(null, null)
    })

  }

  function init () {
    // create slides

    index = -1

    slides = project.slides.map(function (slide) {
      var s = document.createElement('div')
      s.classList.add('project-slide')
      s.style.backgroundImage = 'url(' + slide.image + ')'
      slideWrapper.appendChild(s)

      slide.el = s
      return slide
    })

  }

  function start () {
    next()
    // show first image
    // start timer
  }

  function destroy () {
    window.clearTimeout(timer)
    slideWrapper.innerHTML = ''
  }

  function next () {
    show(index + 1)
    // hide current slide and show next slide
    timer = window.setTimeout(next, INTERVAL)
  }

  function show (i) {
    index = i

    if (index > slides.length -1) {
      index = 0
    }

    if (index < 0) {
      index = slides.length - 1
    }

    var newSlide = slides[index]

    info.innerHTML = infoText
    info.style.color = textColor
    nextButton.style.color = textColor
    prevButton.style.color = textColor

    // fade out new slide and put it on top
    newSlide.el.classList.add('is-ontop')
    newSlide.el.classList.add('is-visible')

    window.setTimeout(function () {
      if (activeSlide) {
        activeSlide.el.classList.remove('is-active')
      }
      newSlide.el.classList.add('is-active')
      newSlide.el.classList.remove('is-ontop')
      newSlide.el.classList.remove('is-visible')
      activeSlide = newSlide
    }, 2000)

  }

  return {
    load: load,
    start: start,
    init: init,
    destroy: destroy
  }

}