var languageLinks = Array.prototype.map.call(
  document.querySelectorAll('.language-switch__link'),
  function(link) {
    return {
      el: link,
      slug: link.getAttribute('data-language-slug'),
    }
  }
)

var activeLink = languageLinks[0]
var activeLanguage = activeLink.slug

switchLanguage(activeLink)

languageLinks.forEach(function(link) {
  link.el.addEventListener('click', function(e) {
    e.preventDefault()

    switchLanguage(link)
  })
})

function switchLanguage(link) {
  document.body.classList.remove(activeLanguage)
  activeLanguage = link.slug
  document.body.classList.add(activeLanguage)
  activeLink.el.classList.remove('is-active')
  activeLink = link
  activeLink.el.classList.add('is-active')
}
