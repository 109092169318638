var Tween = require("/usr/local/var/www/virtual/reanalog/scripts/tween.js")
var Action = require("/usr/local/var/www/virtual/reanalog/scripts/action.js")
var easing = require("/usr/local/var/www/virtual/reanalog/scripts/easing.js")
var videoDuration = require("/usr/local/var/www/virtual/reanalog/scripts/video-duration.js")
var pubsub = require("/usr/local/var/www/virtual/reanalog/scripts/pubsub.js")
var easteregg = require("/usr/local/var/www/virtual/reanalog/scripts/easteregg.js")

function show(selector) {
  var el = document.querySelector(selector)
  el.classList.add('is-active')
}

function hide(selector) {
  var el = document.querySelector(selector)
  el.classList.remove('is-active')
}

var chapters = [
  {
    // analoge Bildausarbeitung seit 1985
    name: 'chapter1',
    pause: 0,
    onEnter: function(byClick) {
      show('.chapter1')
    },
    onExitPause: function() {},
    onExit: function(byClick) {
      if (byClick) {
        hide('.chapter1')
      }
    },

    clips: [
      // reset first elements

      {
        start: 0.01,
        end: 0.1,
        tweens: [
          new Tween({
            selector: '.chapter1__sub-chapter1',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
          new Tween({
            selector: '.chapter1__sub-chapter1',
            property: 'translateY',
            from: 100,
            to: 100,
            easing: easing.Quadratic.InOut,
          }),
          new Tween({
            selector: '.chapter1__sub-chapter2',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
          new Tween({
            selector: '.chapter1__sub-chapter2',
            property: 'translateY',
            from: 100,
            to: 100,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
      {
        start: 0,
        end: 0.5,
        tweens: [
          new Tween({
            selector: '.chapter1__sub-chapter1',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
          new Tween({
            selector: '.chapter1__sub-chapter1',
            property: 'translateY',
            from: 100,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.5,
        end: 0.8,
        tweens: [
          new Tween({
            selector: '.chapter1__sub-chapter2',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
          new Tween({
            selector: '.chapter1__sub-chapter2',
            property: 'translateY',
            from: 100,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.0,
        end: 1.2,
        tweens: [
          new Tween({
            selector: '.chapter1__sub-chapter1',
            property: 'opacity',
            from: 1,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
          new Tween({
            selector: '.chapter1__sub-chapter1',
            property: 'translateY',
            from: 0,
            to: -100,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.1,
        end: 1.3,
        tweens: [
          new Tween({
            selector: '.chapter1__sub-chapter2',
            property: 'opacity',
            from: 1,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
          new Tween({
            selector: '.chapter1__sub-chapter2',
            property: 'translateY',
            from: 0,
            to: -100,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.3,
        end: 1.35,
        tweens: [
          new Tween({
            selector: '.chapter1__sub-chapter1',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.4,
        end: 1.45,
        tweens: [
          new Tween({
            selector: '.chapter1__sub-chapter2',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
    ],

    actions: [],
  },

  {
    // Ausbelichtung von digitalen Daten auf SW-Negativfilm
    name: 'chapter2',
    pause: 7.5,
    onEnter: function(byClick) {
      show('.chapter2')
    },
    onExitPause: function(byClick) {},
    onExit: function(byClick) {
      if (byClick) {
        hide('.chapter2')
      }
    },

    clips: [
      {
        start: 0,
        end: 0.1,
        tweens: [
          new Tween({
            selector: '.chapter2__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
      {
        start: 0.99,
        end: 1.0,
        tweens: [
          new Tween({
            selector: '.chapter2__content',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.99,
        end: 1.0,
        tweens: [
          new Tween({
            selector: '.chapter2__content',
            property: 'translateY',
            from: 100,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
      {
        start: 1.1,
        end: 1.3,
        tweens: [
          new Tween({
            selector: '.chapter2__content',
            property: 'opacity',
            from: 1,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.1,
        end: 1.4,
        tweens: [
          new Tween({
            selector: '.chapter2__content',
            property: 'translateY',
            from: 0,
            to: -100,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.4,
        end: 1.5,
        tweens: [
          new Tween({
            selector: '.chapter2__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
    ],

    actions: [],
  },

  {
    // maschinelle filmentwicklung
    name: 'chapter3',
    pause: 14,
    onEnter: function() {
      show('.chapter3')

      /*var chap2 = document.querySelector('.chapter2')
      chap2.classList.add('dark')
      var header = document.getElementById('header')
      header.classList.add('dark')*/
    },
    onExitPause: function() {
      /*
      var header = document.getElementById('header')
      header.classList.remove('dark')
      */
    },
    onExit: function(byClick) {
      if (byClick) {
        hide('.chapter3')
      }
    },

    actions: [
      new Action({
        time: 0,
        before: function() {
          document.body.classList.remove('dark')
        },
        after: function() {
          document.body.classList.add('dark')
        },
      }),

      new Action({
        time: 0.36,
        before: function() {
          document.body.classList.add('dark')
        },
        after: function() {
          document.body.classList.remove('dark')
        },
      }),
    ],

    clips: [
      {
        // header background
        start: -0.025,
        end: 0,
        tweens: [
          new Tween({
            selector: '.header',
            property: 'backgroundColor',
            from: 'rgb(255,255,255)',
            to: 'rgb(30,30,30)',
            //to: 'rgb(50,10,10)',
            easing: easing.Quadratic.InOut,
          }),

          new Tween({
            selector: '.chapter3-overlay',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        // header background
        start: 0.3,
        end: 0.36,
        tweens: [
          new Tween({
            selector: '.header',
            property: 'backgroundColor',
            from: 'rgb(30,30,30)',
            to: 'rgb(255,255,255)',
            //to: 'rgb(50,10,10)',
            easing: easing.Quadratic.InOut,
          }),

          new Tween({
            selector: '.chapter3-overlay',
            property: 'opacity',
            from: 1,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0,
        end: 0.1,
        tweens: [
          new Tween({
            selector: '.chapter3__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
      {
        start: 0.7,
        end: 0.9,
        tweens: [
          new Tween({
            selector: '.chapter3__content',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.7,
        end: 0.9,
        tweens: [
          new Tween({
            selector: '.chapter3__content',
            property: 'translateY',
            from: 100,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
      {
        start: 1.0,
        end: 1.1,
        tweens: [
          new Tween({
            selector: '.chapter3__content',
            property: 'opacity',
            from: 1,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.0,
        end: 1.1,
        tweens: [
          new Tween({
            selector: '.chapter3__content',
            property: 'translateY',
            from: 0,
            to: -100,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.1,
        end: 1.2,
        tweens: [
          new Tween({
            selector: '.chapter3__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
    ],
  },

  {
    name: 'chapter4',
    // bis negativ leuchtet
    // led
    pause: 21.2,
    onEnter: function() {
      show('.chapter4')
    },
    onExitPause: function() {},
    onExit: function(byClick) {
      if (byClick) {
        hide('.chapter4')
      }
    },

    clips: [
      {
        start: 0,
        end: 0.1,
        tweens: [
          new Tween({
            selector: '.chapter4__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        // color
        start: 0.22,
        end: 0.45,
        tweens: [
          new Tween({
            selector: '.header',
            property: 'backgroundColor',
            from: 'rgb(255,255,255)',
            //to: 'rgb(210,90,90)',
            to: 'rgb(215,112,123)',
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.5,
        end: 0.6,
        tweens: [
          new Tween({
            selector: '.chapter4__content',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.5,
        end: 0.6,
        tweens: [
          new Tween({
            selector: '.chapter4__content',
            property: 'translateY',
            from: 100,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        // color
        start: 0.9,
        end: 1,
        tweens: [
          new Tween({
            selector: '.header',
            property: 'backgroundColor',
            from: 'rgb(215,112,123)',
            to: 'rgb(185,82,93)',
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1,
        end: 1.05,
        tweens: [
          new Tween({
            selector: '.chapter4__content',
            property: 'opacity',
            from: 1,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1,
        end: 1.05,
        tweens: [
          new Tween({
            selector: '.chapter4__content',
            property: 'translateY',
            from: 0,
            to: -100,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.05,
        end: 1.06,
        tweens: [
          new Tween({
            selector: '.chapter4__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
    ],

    actions: [],
  },

  {
    // maschinelle Entwicklung mit klassischer ‚Schalenchemie’
    name: 'chapter5',
    pause: 40,
    onEnter: function() {
      show('.chapter5')
    },
    onExitPause: function() {},
    onExit: function(byClick) {
      if (byClick) {
        hide('.chapter5')
      }
    },

    clips: [
      {
        start: 0,
        end: 0.1,
        tweens: [
          new Tween({
            selector: '.chapter5__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        // color
        start: 0.2,
        end: 0.25,
        tweens: [
          new Tween({
            selector: '.header',
            property: 'backgroundColor',
            from: 'rgb(185,82,93)',
            to: 'rgb(215,112,123)',
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.6,
        end: 0.8,
        tweens: [
          new Tween({
            selector: '.chapter5__content',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.6,
        end: 0.8,
        tweens: [
          new Tween({
            selector: '.chapter5__content',
            property: 'translateY',
            from: 100,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
      {
        start: 1,
        end: 1.1,
        tweens: [
          new Tween({
            selector: '.chapter5__content',
            property: 'opacity',
            from: 1,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1,
        end: 1.1,
        tweens: [
          new Tween({
            selector: '.chapter5__content',
            property: 'translateY',
            from: 0,
            to: -100,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.1,
        end: 1.12,
        tweens: [
          new Tween({
            selector: '.chapter5__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
    ],

    actions: [],
  },

  {
    // verzeichnungsfreie trocknung
    name: 'chapter6',
    pause: 58,
    onEnter: function(enteredByClick) {
      show('.chapter6')
    },
    onExitPause: function() {},
    onExit: function(byClick) {
      if (byClick) {
        hide('.chapter6')
      }
    },

    clips: [
      {
        start: 0,
        end: 0.1,
        tweens: [
          new Tween({
            selector: '.chapter6__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        // color
        start: 0.65,
        end: 1,
        tweens: [
          new Tween({
            selector: '.header',
            property: 'backgroundColor',
            from: 'rgb(215,112,123)',
            to: 'rgb(255,255,255)',
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.8,
        end: 1,
        tweens: [
          new Tween({
            selector: '.chapter6__content',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.8,
        end: 1,
        tweens: [
          new Tween({
            selector: '.chapter6__content',
            property: 'translateY',
            from: 100,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1,
        end: 1.1,
        tweens: [
          new Tween({
            selector: '.chapter6__content',
            property: 'opacity',
            from: 1,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1,
        end: 1.15,
        tweens: [
          new Tween({
            selector: '.chapter6__content',
            property: 'translateY',
            from: 0,
            to: -100,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
    ],

    actions: [],
  },

  {
    // bis papierrolle liegt
    // Verarbeitungszeit eines 142 x 300 cm Abzugs inkl. Trocknung unter 1 Stunde
    name: 'chapter7',
    pause: 60 + 11.8,
    onEnter: function() {
      show('.chapter7')
    },
    onExitPause: function() {},
    onExit: function(byClick) {
      if (byClick) {
        hide('.chapter7')
      }
    },

    clips: [
      {
        start: 0,
        end: 0.1,
        tweens: [
          new Tween({
            selector: '.chapter7__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
      {
        start: 0.6,
        end: 0.9,
        tweens: [
          new Tween({
            selector: '.chapter7__content',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.6,
        end: 0.9,
        tweens: [
          new Tween({
            selector: '.chapter7__content',
            property: 'translateY',
            from: 100,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.0,
        end: 1.1,
        tweens: [
          new Tween({
            selector: '.chapter7__content',
            property: 'opacity',
            from: 1,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.05,
        end: 1.1,
        tweens: [
          new Tween({
            selector: '.chapter7__content',
            property: 'translateY',
            from: 0,
            to: -100,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
    ],

    actions: [],
  },

  {
    // bis papierbogen
    name: 'chapter8',
    pause: 60 + 21,
    onEnter: function() {
      show('.chapter8')
    },
    onExitPause: function() {},
    onExit: function(byClick) {
      if (byClick) {
        hide('.chapter8')
      }
    },

    clips: [
      {
        start: 0,
        end: 0.1,
        tweens: [
          new Tween({
            selector: '.chapter8__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
      {
        start: 0.7,
        end: 1,
        tweens: [
          new Tween({
            selector: '.chapter8__content',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.7,
        end: 1,
        tweens: [
          new Tween({
            selector: '.chapter8__content',
            property: 'translateY',
            from: 100,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1,
        end: 1.1,
        tweens: [
          new Tween({
            selector: '.chapter8__content',
            property: 'opacity',
            from: 1,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1,
        end: 1.1,
        tweens: [
          new Tween({
            selector: '.chapter8__content',
            property: 'translateY',
            from: 0,
            to: -100,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 1.12,
        end: 1.13,
        tweens: [
          new Tween({
            selector: '.chapter8__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
    ],

    actions: [],
  },

  {
    // weiße fläche
    name: 'chapter9',
    pause: 60 + 26.5,
    onEnter: function() {
      show('.chapter9')
    },
    onExitPause: function() {},
    onExit: function(byClick) {
      if (byClick) {
        hide('.chapter9')
      }
    },

    clips: [
      {
        start: 0,
        end: 0.1,
        tweens: [
          new Tween({
            selector: '.chapter9__content',
            property: 'opacity',
            from: 0,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
      {
        start: 0.1,
        end: 0.3,
        tweens: [
          new Tween({
            selector: '.chapter9__content',
            property: 'opacity',
            from: 0,
            to: 1,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },

      {
        start: 0.1,
        end: 0.3,
        tweens: [
          new Tween({
            selector: '.chapter9__content',
            property: 'translateY',
            from: 100,
            to: 0,
            easing: easing.Quadratic.InOut,
          }),
        ],
      },
    ],

    actions: [],
  },

  {
    // end
    name: 'end',
    pause: videoDuration,
    onEnter: function() {
      pubsub.publish('/end/reached', {})
    },
    onExitPause: function() {},
    onExit: function(byClick) {
      if (byClick) {
      }
    },
    clips: [],
    actions: [],
  },
]

chapters = chapters.map(function(chapter, i) {
  chapter.start = chapter.pause

  if (chapters[i + 1]) {
    chapter.end = chapters[i + 1].pause
  } else {
    chapter.end = videoDuration
  }

  chapter.duration = chapter.end - chapter.start

  // calculate absolute time values for clips and actions so we dont have to do this in the update loop later every time
  chapter.clips.forEach(function(clip) {
    var start = chapter.start + (chapter.end - chapter.start) * clip.start
    var end = chapter.start + (chapter.end - chapter.start) * clip.end
    var duration = end - start

    clip.startAbs = start
    clip.endAbs = end
    clip.durationAbs = duration

    clip.numTweens = clip.tweens.length
  })

  chapter.actions.forEach(function(action) {
    action.timeAbs = chapter.start + (chapter.end - chapter.start) * action.time
  })

  return chapter
})

module.exports = chapters
