// fit video into screen:
var debounce = require("/usr/local/var/www/virtual/reanalog/node_modules/lodash.debounce/index.js")

module.exports = function(video) {
  var vpW
  var vpH

  var videoWidth
  var videoHeight
  var dResize = debounce(resize, 200)

  //var video = document.getElementById('video')
  var videoWidth = parseInt(video.getAttribute('data-width'), 10)
  var videoHeight = parseInt(video.getAttribute('data-height'), 10)
  var videoAr = videoHeight / videoWidth

  // default: center center
  var focus = {
    top: 0.5,
    left: 0.5,
  }

  function resize() {
    vpW = window.innerWidth
    vpH = window.innerHeight

    var vpAr = vpH / vpW

    if (vpAr > 0.8) {
      video.style.width = ''
      video.style.height = ''
      video.style.left = ''
      video.style.top = ''
      return
    }

    if (vpAr > videoAr) {
      // viewport is more portrait than video
      // -> make video 100% height
      videoWidth = vpH / videoAr
      videoHeight = vpH
    } else {
      videoWidth = vpW
      videoHeight = videoAr * vpW
    }

    video.style.width = videoWidth + 'px'
    video.style.height = videoHeight + 'px'

    setFocus()
  }

  function setFocus(left, top) {
    if (typeof left !== 'undefined') {
      focus.left = left
    }

    if (typeof top !== 'undefined') {
      focus.top = top
    }

    video.style.left = -(videoWidth - vpW) * focus.left + 'px'
    video.style.top = -(videoHeight - vpH) * focus.top + 'px'
  }

  window.addEventListener('resize', dResize)

  resize()

  return {
    setFocus: setFocus,
  }
}
