;(function() {
  var video = document.getElementById('video')

  if (!video) {
    return
  }

  var fitvid = require("/usr/local/var/www/virtual/reanalog/scripts/fit-video.js")(video)

  fitvid.setFocus(0, 0)
})()
