var element
var onWheel

function wheel(event) {
  event.preventDefault()
  var delta = 0

  if (event.wheelDelta) {
    delta = -event.wheelDelta / 120
  } else if (event.detail) {
    delta = event.detail / 3
  }

  if (delta) {
    onWheel(delta)
  }
}

function addListeners() {
  if (element) {
    element.addEventListener('mousewheel', wheel, false)
    element.addEventListener('DOMMouseScroll', wheel, false)
  }
}

function removeListeners() {
  if (element) {
    element.removeEventListener('mousewheel', wheel, false)
    element.removeEventListener('DOMMouseScroll', wheel, false)
  }
}

function activate() {
  addListeners()
}

function deactivate() {
  removeListeners()
}

function init(options) {
  element = options.element || window
  onWheel = options.onWheel || function() {}
}

module.exports = {
  init: init,
  activate: activate,
  deactivate: deactivate,
}
