var header = document.getElementById('header')

var isOpen = header.classList.contains('header--is-open')

var toggleBtn = document.querySelector('.nav-toggle')

toggleBtn.addEventListener('click', toggle)

var links = header.querySelectorAll('.language-switch__link, .main-nav__link')

Array.prototype.forEach.call(links, function(link) {
  link.addEventListener('click', close)
})

function toggle() {
  if (isOpen) {
    close()
  } else {
    open()
  }
}

function open() {
  header.classList.add('header--is-open')
  isOpen = true
  scrollBuffer = 0
}

function close() {
  header.classList.remove('header--is-open')
  isOpen = false
  scrollBuffer = 0
}

var scrollBuffer = 0

window.addEventListener('scroll', function() {
  scrollBuffer += 1

  if (scrollBuffer > 15) {
    close()
  }
})

module.exports = {
  open: open,
  close: close,
}
