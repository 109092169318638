cookies = require("/usr/local/var/www/virtual/reanalog/node_modules/cookies-js/dist/cookies.js")

const ACCEPT_KEY = 'recomart_accept_cookies'

;(function () {

  var element = document.querySelector('.cookie-disclaimer')
  var acceptButton = element.querySelector('.cookie-disclaimer__accept')

  if (cookies.enabled) {
    // check whether cookies were accepted previously.
    // if not, show the disclaimer
    if (!cookies.get(ACCEPT_KEY)) {
      show()
    } else {
      // accept handler
      accept()
    }
  }

  acceptButton.addEventListener('click', accept)

  function accept() {
    cookies.set(ACCEPT_KEY, 'true', { expires: Infinity })
    hide()
  }

  function show() {
    element.classList.add('is-active')
  }

  function hide() {
    element.classList.remove('is-active')
  }

}())


