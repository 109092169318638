var async = require("/usr/local/var/www/virtual/reanalog/node_modules/async/lib/async.js")
var makeSlideshow = require("/usr/local/var/www/virtual/reanalog/scripts/project-slideshow.js")
var makeVideo = require("/usr/local/var/www/virtual/reanalog/scripts/project-video.js")
var makeSphere = require("/usr/local/var/www/virtual/reanalog/scripts/project-sphere.js")

function main() {
  var wrapper = document.getElementById('project')
  if (!wrapper) {
    return
  }

  var imageSize = screen.width < 769 ? '-small' : ''

  var wait = function(callback) {
    setTimeout(function() {
      callback(null, null)
    }, 1000)
  }

  var projects = [
    {
      type: 'SLIDESHOW',
      info:
        'BIEN-U BAE<span class="hide-on-mobile"> – </span><br class="hide-on-desktop">Part : Meet<br>Axel Vervoordt Gallery, Hong Kong',
      slides: [
        {
          image: SITE_URL + '/img/projects/bien-u-bae-0.jpg',
        },
        {
          image: SITE_URL + '/img/projects/bien-u-bae-1.jpg',
        },
        {
          image: SITE_URL + '/img/projects/bien-u-bae-2.jpg',
        },
        {
          image: SITE_URL + '/img/projects/bien-u-bae-3.jpg',
        },
        {
          image: SITE_URL + '/img/projects/bien-u-bae-4.jpg',
        },
      ],
      backgroundColor: 'rgb(200,200,200)',
      textColor: 'rgb(30,30,30)',
    },

    {
      type: 'VIDEO',
      info:
        'MATTHEW BROOKES<span class="hide-on-mobile"> – </span><br class="hide-on-desktop">Les danseurs<br>vnh gallery Paris',
      //src: SITE_URL + '/img/projects/film-des-danseurs.mp4',
      src: {
        '1080':
          'https://player.vimeo.com/external/176290070.hd.mp4?s=b8046f9349069a602b8889940e285d41584285ab&profile_id=119',
        '720':
          'https://player.vimeo.com/external/176290070.hd.mp4?s=b8046f9349069a602b8889940e285d41584285ab&profile_id=174',
        '540':
          'https://player.vimeo.com/external/176290070.sd.mp4?s=719f2b2a8ee0ab4382b5887b2e50f470b46bdc38&profile_id=165',
        '360':
          'https://player.vimeo.com/external/176290070.sd.mp4?s=719f2b2a8ee0ab4382b5887b2e50f470b46bdc38&profile_id=164',
      },
      aspect: 9 / 16,
      backgroundColor: 'rgb(200,200,200)',
      textColor: 'rgb(30,30,30)',
      loop: false,
    },

    {
      type: 'SPHERE',
      info:
        'PETER PUKLUS<span class="hide-on-mobile"> – </span><br class="hide-on-desktop">The epic love story of a warrior<br>C|O Berlin',
      image:
        SITE_URL + '/img/spheres/PeterPuklus_COBERLIN' + imageSize + '.jpg',
      minFov: 40,
      maxFov: 85,
      minLat: -45,
      maxLat: 45,
      startLon: screen.width < 769 ? 115.6 : 143.6,
      startFov: 85,
      startLat: screen.width < 769 ? 0 : 0.9,
      textColor: 'rgb(255,255,255)',
    },

    {
      type: 'SPHERE',
      info:
        'AXEL HÜTTE - Strange and Familiar<span class="hide-on-mobile">: Britain as Revealed by International Photographers</span><br>Barbican Art Gallery',
      image: SITE_URL + '/img/spheres/Barbican-Center' + imageSize + '.jpg',
      minFov: 40,
      maxFov: 85,
      minLat: -10,
      maxLat: 10,
      minLon: window.innerWidth < 769 ? -110 : -70,
      maxLon: window.innerWidth < 769 ? 40 : 1,
      startLon: -124,
      startFov: 85,
      startLat: screen.width < 769 ? 0 : 0.9,
      textColor: 'rgb(255,255,255)',
    },
  ]

  // create array of project instances
  projects = projects.map(function(project) {
    if (project.type === 'SLIDESHOW') {
      return makeSlideshow(project)
    }

    if (project.type === 'VIDEO') {
      return makeVideo(project)
    }

    if (project.type === 'SPHERE') {
      return makeSphere(project)
    }
  })

  var loader = document.getElementById('project-loader')
  var nextBtn = wrapper.querySelector('.project-nav--next')
  var prevBtn = wrapper.querySelector('.project-nav--prev')
  var info = wrapper.querySelector('.project-info')

  nextBtn.addEventListener('click', next)
  prevBtn.addEventListener('click', prev)

  var index = 0
  var currentProject

  function next() {
    show(index + 1)
  }

  function prev() {
    show(index - 1)
  }

  function show(i) {
    if (i < 0) {
      i = projects.length - 1
    }
    if (i > projects.length - 1) {
      i = 0
    }
    index = i

    var newProject = projects[index]

    // fade in loader overlay
    loader.classList.add('loading')

    // fade in new sphere

    // wait at least 1 second!
    async.parallel([newProject.load, wait], function(err, results) {
      // destroy old project
      if (currentProject) {
        currentProject.destroy()
      }
      newProject.init()

      // fade out loader overlay
      loader.classList.remove('loading')

      newProject.start()

      currentProject = newProject
    })
  }

  show(0)
}

main()
