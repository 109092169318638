/* =======================================

  Animated scrolling

  usage: animatedScroll({ to:500, duration: 500 }, function(){});

==========================================*/
//var easing = require('./easing');

module.exports = function(options, callback) {

  var start = Date.now();
  var scrollProperty;
  var el = options.el || window;
  var from = window.pageYOffset;

  if (options.el) {
    from = el.scrollTop;
  }

  var easingFunction = options.easing || easeInOutQuad;
  var to = options.to;
  var duration = options.duration || 500;

  if (from === to) {
    if (callback) {
      callback();
    }
    return;
  }

  function min(a, b) {
    return a < b ? a : b;
  }

  function scroll(timestamp) {
    var currentTime = Date.now();
    var time = min(1, ((currentTime - start) / duration));
    var easedT = easingFunction(time);
    var current = (easedT * (to - from)) + from;

    if (el === window) {
      window.scrollTo(0, current);
    }else {
      el.scrollTop = current;
    }

    if (time < 1) {
      requestAnimationFrame(scroll);
    }else {
      if (callback) {
        callback();
      }
    }
  }
  requestAnimationFrame(scroll);

  function easeInOutQuad(t) {
    return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  }

};
