var Hammer = require("/usr/local/var/www/virtual/reanalog/node_modules/hammerjs/hammer.js")

module.exports = function(project) {
  var container = document.getElementById('sphere-canvas')
  var wrapper = document.getElementById('project')
  var info = wrapper.querySelector('.project-info')
  var nextButton = document.querySelector('.project-nav--next')
  var prevButton = document.querySelector('.project-nav--prev')
  var infoText = project.info
  var textColor = project.textColor

  var imageSrc = project.image

  var minFov = project.minFov
  var maxFov = project.maxFov
  var minLat = project.minLat
  var maxLat = project.maxLat
  var minLon = project.minLon
  var maxLon = project.maxLon
  var startLon = project.startLon
  var startFov = project.startFov
  var startLat = project.startLat

  var isUserInteracting = false
  var lon = startLon
  var lat = startLat
  var onMouseDownLon = 0
  var onMouseDownLat = 0
  var onMouseDownMouseX = 0
  var onMouseDownMouseY = 0
  var phi = 0
  var theta = 0
  var camera
  var scene
  var renderer
  var mesh
  var vpW
  var texture

  var loader = new THREE.TextureLoader()

  var animationFrameRequest

  var hammertime

  function load(callback) {
    loader.load(
      imageSrc,
      function(tex) {
        texture = tex
        callback(null, null)
      },
      function(xhr) {},
      function(xhr) {}
    )
  }

  function init() {
    container.classList.add('is-active')

    camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      1,
      1100
    )
    camera.target = new THREE.Vector3(0, 0, 0)
    scene = new THREE.Scene()
    var geometry = new THREE.SphereGeometry(500, 60, 40)
    geometry.scale(-1, 1, 1)

    var material = new THREE.MeshBasicMaterial({
      //map: THREE.ImageUtils.loadTexture('/img/raum1_cropped_bilinear.jpg')
      map: THREE.ImageUtils.loadTexture(imageSrc),
    })

    mesh = new THREE.Mesh(geometry, material)
    scene.add(mesh)
    renderer = new THREE.WebGLRenderer()
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(wrapper.offsetWidth, wrapper.offsetHeight)
    container.appendChild(renderer.domElement)

    document.addEventListener('mousedown', onDocumentMouseDown, false)
    document.addEventListener('mousemove', onDocumentMouseMove, false)
    document.addEventListener('mouseup', onDocumentPointerUp, false)
    container.addEventListener('mousewheel', onDocumentMouseWheel, false)
    container.addEventListener(
      'MozMousePixelScroll',
      onDocumentMouseWheel,
      false
    )

    if (Modernizr.touchevents) {
      hammertime = new Hammer(container, {})
      hammertime.get('pinch').set({ enable: true })

      hammertime.on('pan', function(e) {
        var relFov = (camera.fov - minFov) / (maxFov - minFov)
        var rel = 0.5 + relFov * (1 - 0.5)
        lon -= e.velocityX * 3 * rel
        lat += e.velocityY * 3 * rel
      })

      hammertime.on('pinchstart', function(e) {
        lastFov = camera.fov
      })

      hammertime.on('pinch', function(e) {
        var scale = e.scale
        var fov = lastFov / scale

        if (fov < minFov) {
          fov = minFov
        }

        if (fov > maxFov) {
          fov = maxFov
        }

        camera.fov = fov
        camera.updateProjectionMatrix()
      })
    }

    mesh.material.map = texture
    mesh.material.needsUpdate = true
    wrapper.offsetWidth
    info.innerHTML = infoText
    info.style.color = textColor
    nextButton.style.color = textColor
    prevButton.style.color = textColor
    camera.fov = startFov

    window.addEventListener('resize', onWindowResize, false)
  }

  function onWindowResize() {
    var wrapperHeight = wrapper.offsetHeight
    var wrapperWidth = wrapper.offsetWidth
    camera.aspect = wrapperWidth / wrapperHeight
    camera.updateProjectionMatrix()
    renderer.setSize(wrapperWidth, wrapperHeight)
  }

  function onDocumentMouseDown(event) {
    event.preventDefault()
    isUserInteracting = true
    onPointerDownPointerX = event.clientX
    onPointerDownPointerY = event.clientY
    onPointerDownLon = lon
    onPointerDownLat = lat
  }

  function onDocumentTouchStart(event) {
    event.preventDefault()
    isUserInteracting = true
    onPointerDownPointerX = event.touches[0].clientX
    onPointerDownPointerY = event.touches[0].clientY
    onPointerDownLon = lon
    onPointerDownLat = lat
  }

  function onDocumentMouseMove(event) {
    if (isUserInteracting === true) {
      onDrag(event.clientX, event.clientY)
    }
  }

  function onDocumentTouchMove(event) {
    event.preventDefault()
    onDrag(event.touches[0].clientX, event.touches[0].clientY)
  }

  function onDrag(x, y) {
    lon = (onPointerDownPointerX - x) * 0.1 + onPointerDownLon
    lat = (y - onPointerDownPointerY) * 0.1 + onPointerDownLat
  }

  function onDocumentPointerUp(event) {
    isUserInteracting = false
  }

  function onDocumentMouseWheel(event) {
    // WebKit
    if (event.wheelDeltaY) {
      camera.fov -= event.wheelDeltaY * 0.05
      // Opera / Explorer 9
    } else if (event.wheelDelta) {
      camera.fov -= event.wheelDelta * 0.05
      // Firefox
    } else if (event.detail) {
      camera.fov += event.detail * 1.0
    }

    if (camera.fov > maxFov) {
      camera.fov = maxFov
    }
    if (camera.fov < minFov) {
      camera.fov = minFov
    }

    camera.updateProjectionMatrix()
  }

  function animate() {
    animationFrameRequest = requestAnimationFrame(animate)
    update()
  }

  function update() {
    if (isUserInteracting === false) {
      //lon += 0.1
    }

    if (minLat && maxLat) {
      lat = Math.max(minLat, Math.min(maxLat, lat))
    }
    if (minLon && maxLon) {
      lon = Math.max(minLon, Math.min(maxLon, lon))
    }

    phi = THREE.Math.degToRad(90 - lat)
    theta = THREE.Math.degToRad(lon)
    camera.target.x = 500 * Math.sin(phi) * Math.cos(theta)
    camera.target.y = 500 * Math.cos(phi)
    camera.target.z = 500 * Math.sin(phi) * Math.sin(theta)
    camera.lookAt(camera.target)
    /*
    // distortion
    camera.position.copy( camera.target ).negate();
    */
    renderer.render(scene, camera)
  }

  function start() {
    animationFrameRequest = requestAnimationFrame(animate)
  }

  function destroy() {
    cancelAnimationFrame(animationFrameRequest)
    document.removeEventListener('mousedown', onDocumentMouseDown, false)
    document.removeEventListener('mousemove', onDocumentMouseMove, false)
    document.removeEventListener('mouseup', onDocumentPointerUp, false)
    container.removeEventListener('mousewheel', onDocumentMouseWheel, false)
    container.removeEventListener(
      'MozMousePixelScroll',
      onDocumentMouseWheel,
      false
    )
    container.classList.remove('is-active')

    container.removeChild(container.firstElementChild)

    if (hammertime) {
      hammertime.destroy()
    }
  }

  return {
    load: load,
    init: init,
    start: start,
    destroy: destroy,
  }
}
