var pubsub = require("/usr/local/var/www/virtual/reanalog/scripts/pubsub.js")
var noop = function() {}
var video
var isActive
var onUpdate
var onComplete
var from
var to
var animationFrameRequest

// for backwards playing
var startTime
var duration
var SPEED = 2

function init(vid) {
  video = vid
}

function play(_from, _to, _onUpdate, _onComplete) {
  cancelAnimationFrame(animationFrameRequest)

  onUpdate = _onUpdate || noop
  onComplete = _onComplete || noop

  if (_from === _to) {
    onComplete()
    return
  }
  isActive = true

  if (_from < _to) {
    from = _from
    to = _to
    playForwards()
  } else {
    from = _from * 1000
    to = _to * 1000
    playBackwards()
  }

  pubsub.publish('/video/playing')
}

function playForwards() {
  video.currentTime = from
  video.play()
  animationFrameRequest = requestAnimationFrame(updateForwards)
}

function playBackwards() {
  startTime = Date.now()
  duration = Math.abs(to - from) * (1 / SPEED)
  // kick off
  animationFrameRequest = requestAnimationFrame(updateBackwards)
}

function updateForwards() {
  if (!isActive) {
    return
  }

  var currentTime = video.currentTime
  onUpdate(currentTime)

  if (currentTime < to) {
    animationFrameRequest = requestAnimationFrame(updateForwards)
  } else {
    video.pause()
    onComplete()
    isActive = false
  }
}

function updateBackwards() {
  if (!isActive) {
    return
  }
  var currentTime = Date.now()
  var time = min(1, (currentTime - startTime) / duration)
  var current = time * (to - from) + from
  current = Math.floor(current)
  current = current / 1000
  video.currentTime = current
  onUpdate(current)

  if (time < 1) {
    animationFrameRequest = requestAnimationFrame(updateBackwards)
  } else {
    onComplete()
    isActive = false
  }
}

function min(a, b) {
  return a < b ? a : b
}

function stop() {
  isActive = false
  cancelAnimationFrame(animationFrameRequest)
  video.pause()
  from = undefined
  to = undefined
  startTime = undefined
  duration = undefined
}

module.exports = {
  init: init,
  play: play,
  stop: stop,
  isActive: function() {
    return isActive
  },
}
