var pubsub = require("/usr/local/var/www/virtual/reanalog/scripts/pubsub.js")

var videoWrapper = document.getElementById('video-wrapper')
var video = document.createElement('video')
video.setAttribute('data-height', '281')
video.setAttribute('data-width', '500')
video.setAttribute('loop', 'loop')
video.classList.add('dog-video')

var source = document.createElement('source')
source.src = 'video/dog.mp4'
source.type = 'video/mp4'

video.appendChild(source)

videoWrapper.appendChild(video)

var fitvid = require("/usr/local/var/www/virtual/reanalog/scripts/fit-video.js")(video)

fitvid.setFocus(0, 0)

var waitTimeout

function wait() {
  window.clearTimeout(waitTimeout)
  waitTimeout = window.setTimeout(show, 15000)
}

function show() {
  videoWrapper.classList.add('show-dog-video')
  video.currentTime = 3
  video.classList.add('is-active')
  video.play()
}

function hide() {
  window.clearTimeout(waitTimeout)
  video.pause()
  video.classList.remove('is-active')
  videoWrapper.classList.remove('show-dog-video')
}

//startSimulation and pauseSimulation defined elsewhere
function handleVisibilityChange() {
  if (document.hidden) {
    //
  } else {
    // start dog video
    //
    show()
  }
}

window.addEventListener('load', function() {
  window.setTimeout(function() {
    document.addEventListener('visibilitychange', handleVisibilityChange)
  }, 1000)
})

//document.addEventListener('click', hide)
document.addEventListener('mousewheel', hide)
document.addEventListener('DOMMouseScroll', hide)
pubsub.subscribe('/video/playing', hide)
pubsub.subscribe('/end/reached', wait)
