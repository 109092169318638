;(function() {
  var video = document.getElementById('restauration-video')

  if (!video) {
    return
  }

  var source = video.querySelector('source')

  var src

  if (Math.max(screen.width, screen.height) < 480) {
    src = source.getAttribute('data-src-mobile')
  } else {
    src = source.getAttribute('data-src-standard-def')
  }

  source.src = src

  video.load()
})()
