var Q = require("/usr/local/var/www/virtual/reanalog/node_modules/q/q.js")

module.exports = function(srcArray, callback) {
  var images = []
  var promises = []
  var loader

  for (var i = 0; i < srcArray.length; i++) {
    loader = loadImage(srcArray[i])
    promises.push(loader.promise)
    images.push(loader.image)
  }

  Q.all(promises).then(function() {
    callback(images)
  })
}

function loadImage(src) {
  var deferred = Q.defer()
  var image = new Image()
  var test = new Image()

  test.src = src

  if (test.complete) {
    deferred.resolve()
  } else {
    image.onload = function() {
      deferred.resolve()
    }
    image.src = src
  }

  return {
    image: image,
    promise: deferred.promise,
  }
}
