;(function() {
  // elements
  var video = document.getElementById('video')

  if (!video) {
    return
  }

  var wheeler = require("/usr/local/var/www/virtual/reanalog/scripts/wheeler.js")
  var chapters = require("/usr/local/var/www/virtual/reanalog/scripts/chapters.js")

  var chapternav = require("/usr/local/var/www/virtual/reanalog/scripts/chapter-nav.js")
  var scrollProgress = require("/usr/local/var/www/virtual/reanalog/scripts/scroll-progress.js")

  var player = require("/usr/local/var/www/virtual/reanalog/scripts/player.js")
  player.init(video)

  // constants
  var WHEEL_THRESHOLD = 2
  var WHEEL_SENSITIVITY = 0.2

  // state
  var index = 0
  var currentChapter = chapters[index]

  var playButton = document.getElementById('scroll-progress')
  playButton.addEventListener('click', next)

  //
  // WHEEL HANDLING
  //
  var wheelBuffer = 0
  var wheelTimeout

  wheeler.init({
    onWheel: wheel,
  })

  wheeler.activate()

  function wheel(delta) {
    if (player.isActive()) {
      return
    }

    wheelBuffer += delta * WHEEL_SENSITIVITY

    // don’t let wheel buffer get smaller than 0 on first chapter
    if (index === 0) {
      if (wheelBuffer < 0) {
        wheelBuffer = 0
      }
    }

    scrollProgress.update(wheelBuffer / WHEEL_THRESHOLD)

    if (wheelBuffer > WHEEL_THRESHOLD) {
      if (index < chapters.length - 1) {
        next()
      }
      return
    }

    if (wheelBuffer < -WHEEL_THRESHOLD) {
      prev()
      return
    }

    // wenn man rückwärts scrollt, müssen die keyframes vom letzten
    // chapter verwendet werden!

    video.currentTime += delta * WHEEL_SENSITIVITY
    update(video.currentTime)
  }

  function next() {
    if (index >= chapters.length - 1) {
      return
    }

    wheelBuffer = 0

    var start = video.currentTime
    var end = chapters[index].end

    currentChapter.onExitPause()

    scrollProgress.hide()
    chapternav.hideLabel()

    player.play(start, end, update, afterPlayingVideo)
  }

  function prev() {
    if (index < 1) {
      return
    }

    wheelBuffer = 0

    var start = video.currentTime

    // run exit function of current chapter
    currentChapter.onExit()

    index -= 1

    currentChapter = chapters[index]
    // select prev chapter

    currentChapter.onEnter()

    var end = currentChapter.pause
    // set end to currentChapter.start

    scrollProgress.hide()

    player.play(start, end, update, function() {
      scrollProgress.show()
    })
  }

  function show(i) {
    // pause video

    player.stop()

    // update
    wheelBuffer = 0

    currentChapter.onExit(true)

    scrollProgress.hide()
    chapternav.hideLabel()

    index = i

    currentChapter = chapters[index]
    currentChapter.onEnter(true)

    var start = currentChapter.start
    var end = currentChapter.end
    player.play(start, end, update, afterPlayingVideo)
  }

  function afterPlayingVideo() {
    if (index >= chapters.length - 1) {
      return
    }
    currentChapter.onExit()
    index += 1
    currentChapter = chapters[index]
    currentChapter.onEnter()

    if (index >= chapters.length - 1) {
      return
    }

    scrollProgress.show()
    chapternav.showLabel()

    chapternav.update(index, wheelBuffer, video.currentTime)
  }

  function update(currentTime) {
    var tweensToReset = []
    var tweensToFinish = []

    var actionsToReset = []
    var actionsToFinish = []

    chapters.forEach(function(chapter) {
      chapter.clips.forEach(function(clip) {
        var elapsed = currentTime - clip.startAbs
        var rel = elapsed / clip.durationAbs

        if (currentTime < clip.startAbs) {
          tweensToReset.push.apply(tweensToReset, clip.tweens)
        } else if (currentTime > clip.endAbs) {
          tweensToFinish.push.apply(tweensToFinish, clip.tweens)
        } else {
          for (var i = 0; i < clip.numTweens; i++) {
            clip.tweens[i].update(rel)
          }
        }
      })

      chapter.actions.forEach(function(action) {
        if (action.timeAbs > currentTime) {
          actionsToReset.push(action)
        }

        if (action.timeAbs <= currentTime) {
          actionsToFinish.push(action)
        }
      })
    })

    var i
    for (i = 0; i < tweensToFinish.length; i++) {
      tweensToFinish[i].finish()
    }

    for (i = tweensToReset.length - 1; i >= 0; i--) {
      tweensToReset[i].reset()
    }

    for (i = 0; i < actionsToFinish.length; i++) {
      actionsToFinish[i].after()
    }

    for (i = actionsToReset.length - 1; i >= 0; i--) {
      actionsToReset[i].before()
    }

    chapternav.update(index, wheelBuffer, video.currentTime)
  }

  chapternav.init({
    onUpdate: function(i) {
      show(i)
    },
  })

  // initialization stuff
  window.addEventListener('load', function() {
    // initialization
    currentChapter.onEnter()
    update(0)
    scrollProgress.show()
    chapternav.showLabel()
  })
})()
