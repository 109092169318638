var Action = function(options) {
  this.name = options.name || 'some action'
  this.state = undefined
  this.time = options.time
  this.onBefore = options.before || function() {}
  this.onAfter = options.after || function() {}
}

Action.prototype.before = function() {
  if (this.state === 'before') {
    return
  }
  this.state = 'before'
  this.onBefore()
}

Action.prototype.after = function() {
  if (this.state === 'after') {
    return
  }
  this.state = 'after'
  this.onAfter()
}

module.exports = Action
