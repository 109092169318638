var Gator = require("/usr/local/var/www/virtual/reanalog/scripts/gator.js")
var wheeler = require("/usr/local/var/www/virtual/reanalog/scripts/wheeler.js")
var pubsub = require("/usr/local/var/www/virtual/reanalog/scripts/pubsub.js")
;(function() {
  var isOpen = false
  var el = document.getElementById('info')

  var links = Array.prototype.slice.call(
    document.querySelectorAll('.info-link')
  )

  var instance

  function open() {
    links.forEach(function(link) {
      link.classList.add('is-active')
    })
    el.classList.add('is-active')
    isOpen = true
    wheeler.deactivate()
    pubsub.publish('/section/opened')
  }

  function close() {
    links.forEach(function(link) {
      link.classList.remove('is-active')
    })
    el.classList.remove('is-active')
    isOpen = false
    wheeler.activate()
    pubsub.publish('/section/closed')
  }

  function toggle(e) {
    if (e) {
      e.preventDefault()
    }
    if (isOpen) {
      close()
    } else {
      open()
    }
  }

  links.forEach(function(link) {
    link.addEventListener('click', toggle)
  })
})()
