var transe = require("/usr/local/var/www/virtual/reanalog/scripts/transition.js")
var chroma = require("/usr/local/var/www/virtual/reanalog/node_modules/chroma-js/chroma.js")

var Tween = function(options) {
  this.el = document.querySelector(options.selector)
  this.property = options.property
  this.from = options.from
  this.to = options.to
  this.easing = options.easing || function (k) { return k }
  this.state = undefined

  if (options.property === 'backgroundColor' || options.property === 'fill') {
    this.colorscale = chroma.scale([options.from, options.to])
  }

}

Tween.prototype.update = function(rel) {
  /*
  if (rel < 0 || rel > 1) {
    return
  }
  */

  if (rel < 0) {
    this.reset()
    return
  }

  if (rel > 1) {
    this.finish()
    return
  }

  if (!this.el) { return }

  rel = this.easing(rel)

  var val
  var prop = this.property

  if (prop === 'opacity') {
    val = this.from + rel * (this.to - this.from)
  }

  if (prop === 'translateY') {
    val = this.from + rel * (this.to - this.from)
    prop = transe.transform.dom
    val = translate3d(0,val, 0)
  }

  if (prop === 'translateX') {
    val = this.from + rel * (this.to - this.from)
    prop = transe.transform.dom
    val = translate3d(val,0, 0)
  }

  if (prop === 'backgroundColor' || prop === 'fill') {
    val = this.colorscale(rel)
  }

  this.el.style[prop] = val
}

Tween.prototype.finish = function() {
  if (this.state === 'finished') {
    return
  }
  //console.log('finish tween')
  this.state = 'finished'
  this.update(1)
}

Tween.prototype.reset = function() {
  if (this.state === 'reset') {
    return
  }
  //console.log('reset tween')
  this.state = 'reset'
  this.update(0)
}

function translate3d(x,y,z) {
  return 'translate3d(' + x + 'px, ' + y + 'px, ' + z + 'px)'
}

module.exports = Tween
