var Gator = require("/usr/local/var/www/virtual/reanalog/scripts/gator.js")
var aniscroll = require("/usr/local/var/www/virtual/reanalog/scripts/animated-scroll.js")

function init() {
  require("/usr/local/var/www/virtual/reanalog/scripts/main-video.js")
  require("/usr/local/var/www/virtual/reanalog/scripts/language.js")
  require("/usr/local/var/www/virtual/reanalog/scripts/header.js")
  require("/usr/local/var/www/virtual/reanalog/scripts/projects.js")
  require("/usr/local/var/www/virtual/reanalog/scripts/cookie-disclaimer.js")

  if (screen.width > 1024) {
    require("/usr/local/var/www/virtual/reanalog/scripts/video-controller.js")
    require("/usr/local/var/www/virtual/reanalog/scripts/orientation-message.js")
    require("/usr/local/var/www/virtual/reanalog/scripts/info.js")
    require("/usr/local/var/www/virtual/reanalog/scripts/fit-home-video.js")
  } else {
    require("/usr/local/var/www/virtual/reanalog/scripts/mobile-restauration-video.js")
  }

  // initialization stuff
  window.addEventListener('load', function() {
    document.body.classList.remove('loading')
  })
}

init()

// maybe reload page when it is resized?
//window.location.reload(true)

Gator(document).on('click', '.sub-nav .active', function(event) {
  event.preventDefault()
  aniscroll({ to: 0, duration: 500 })
})
