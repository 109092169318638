;(function() {
  var orientationMessage = document.getElementById('orientation-message')

  if (!orientationMessage) {
    return
  }

  var scalingAnimation = orientationMessage.querySelector('.scaling-animation')

  var debounce = require("/usr/local/var/www/virtual/reanalog/node_modules/lodash.debounce/index.js")
  var dResize = debounce(resize, 200)

  var threshold = 0.3

  var currentOrientation

  window.addEventListener('resize', dResize)

  function resize() {
    var vpW = window.innerWidth
    var vpH = window.innerHeight

    var aspect = vpW / vpH

    if (aspect > 16 / 9 + threshold) {
      show('landscape')
      return
    }

    if (aspect < 16 / 9 - threshold) {
      show('portrait')
      return
    }

    hide()
  }

  function hide() {
    orientationMessage.classList.remove(currentOrientation)
    scalingAnimation.classList.remove(currentOrientation)
  }

  function show(orientation) {
    if (currentOrientation) {
      orientationMessage.classList.remove(currentOrientation)
      scalingAnimation.classList.remove(currentOrientation)
    }
    orientationMessage.classList.add(orientation)
    scalingAnimation.classList.add(orientation)
    currentOrientation = orientation
  }

  resize()
})()
